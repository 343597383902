.rotation-icon {
    position: absolute;
    z-index: 9999;
}

@media only screen and (max-width: 767px) {
    .rotation-icon {
        left: 0;
    }
}

@media only screen and (min-width: 768px) {
    .rotation-icon {
        left: 8.5%;
    }
}

@media only screen and (min-width: 992px) {
    .rotation-icon {
        left: 17%;
    }
}

