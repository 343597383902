.app-menu {
    background-color: #eeeeee;
    position: fixed;
    bottom: 0;
    width: 100%;
    height: 4rem;
    border-top: solid 1px #aaaaaa;
    padding-top: 0.5rem;
}

.menu-buttons {
    padding-left: 0;
    list-style-type: none;
    display: flex;
    align-items: stretch;
    justify-content: space-around;
    margin: 0 1em;
}

.menu-button {
    flex: 1 1 auto;
    color: #000;
    text-align: center;
}

.active-button {
    border-top: solid 1px #000;
}

.alert-message {
    position: fixed;
    top: 0;
    z-index: 1;
    text-align: center;
    padding: 5px;
}